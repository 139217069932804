<!-- 编辑党会 -->
<template>
  <el-dialog
    title="提醒"
    :visible.sync="startShow"
    width="30%"
    @click="closeDialog"
    :z-index="12"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <div class="title">
      <i class="el-icon-warning" style="color: #e6a23c"></i>
      <span>会议时间未到,是否提前开始当前会议？</span>
    </div>
    <div style="display: flex; justify-content: flex-end; margin-top: 10%">
      <a-button type="primary" @click="onSubmit"> 保存 </a-button>
      <a-button style="margin-left: 20px" @click="closeDialog"> 取消 </a-button>
    </div>
  </el-dialog>
</template>
  <script>
import api from "./api";
export default {
  components: {},
  data() {
    return {
      api,
      startShow: false,
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    // 打开弹窗
    openModal(record = {}) {
      this.startShow = true;
      this.id = record.id;
    },
    //提交
    async onSubmit() {
      this.$refs.formBox.form.validateFields(async (err, value) => {
        if (err) return;
      });
    },
    //关闭弹窗
    closeDialog() {
      this.startShow = false;
    },
    //刷新代码
    refreshTable() {
      this.$emit("refreshTable");
    },
  },
};
</script>
      
  <style lang="less" scoped>
/deep/.ant-modal {
  top: 0;
}
.title {
  font-weight: 400;
  font-size: 18px;
  color: #2d3138;
  line-height: 16px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  i {
    margin-right: 2%;
  }
}
.button2 {
  display: flex;
  justify-content: space-between;
}
/deep/.ant-form input[type="file"] {
  display: none !important;
}
.el-upload__tip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 2%;
  margin-top: 4%;
  span {
    font-weight: 400;
    font-size: 12px;
    color: #bbbdbf;
    line-height: 20px;
    text-align: left;
    font-style: normal;
    text-transform: none;
  }
}
</style>
      